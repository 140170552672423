import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import advancedFiltersReducer from './slices/advancedFiltersSlice';
import defectReducer from './slices/defectSlice';
import defectInsightsReducer from './slices/defectInsightsSlice';
import filtersReducer from './slices/filtersSlice';
import fleetReducer from './slices/fleetSlice';
import fleetGraphReducer from './slices/fleetGraphSlice';
import notificationsReducer from './slices/notificationsSlice';
import pscReducer from './slices/pscSlice';
import rectificationReducer from './slices/rectificationSlice';
import sharedDefectsReducer from './slices/sharedDefectsSlice';
import userReducer from './slices/userSlice';
import vesselViewReducer from './slices/vesselViewSlice';

export const store = configureStore({
    reducer: {
        advancedFilters: advancedFiltersReducer,
        defect: defectReducer,
        defectInsights: defectInsightsReducer,
        filters: filtersReducer,
        fleet: fleetReducer,
        fleetGraph: fleetGraphReducer,
        notifications: notificationsReducer,
        psc: pscReducer,
        rectification: rectificationReducer,
        sharedDefects: sharedDefectsReducer,
        user: userReducer,
        vesselView: vesselViewReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ 
        serializableCheck: false // we're currently storing dates which can't be serialized
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
