import { createSelector } from "@reduxjs/toolkit";
import { createParametricSelectorHook } from "../../hooks/storeHooks";
import { RootState } from "../store";

export const selectPSC = (state: RootState) => state.psc;

export const selectPSCActiveTab = (state: RootState) => state.psc.activeTab;

export const selectPSCInspectionData = (state: RootState) => state.psc.inspectionsData;

export const selectPSCDeficienciesData = (state: RootState) => state.psc.deficienciesData;

export const selectPSCSummaryByIMO = createParametricSelectorHook(
    createSelector(
        [(state: RootState) => state.psc.summaryData, (_, imo: number) => imo],
        (summaryData, imo) =>
            summaryData.find((summary) => summary.vessel_imo === Number(imo))
    )
);