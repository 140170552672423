import axios from "axios";
import { PSCDeficiency, PSCInspection, PSCSummary } from "../types/psc";

export const getPSCSummaryData = async (userToken: string) => {
    const response: PSCSummary[] = await dataRequest("psc/fleet/summary", userToken);

    return response;
}

export const getPSCInspectionsData = async (userToken: string) => {
    const response: PSCInspection[] = await dataRequest("psc/fleet/inspection", userToken);

    return response;
}

export const getPSCDeficienciesData = async (userToken: string) => {
    const response: PSCDeficiency[] = await dataRequest("psc/fleet/deficiencies", userToken);

    return response;
}

const baseUrl: string = process.env.REACT_APP_PSC_API_URL ?? "";

export const dataRequest = async (endpoint: string, userToken: string, params?: any, data?: any) => {
    const response = await axios.get(baseUrl + endpoint, {
        headers:  {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": 'Bearer ' + userToken
        },
        data,
        params
    });

    return response.data
}