import { PSCInspection } from "../../types/psc";
import { classNames } from "primereact/utils";

export const PSCInspectionDeficienciesBody = (item: PSCInspection) => {
    const value = item.number_of_deficiencies;

    const onClick = () => console.log(value);

    return (
        <button 
            className={classNames("font-semibold psc-table-link-btn", value ? "cursor-pointer read-more-btn underline" : null)}
            onClick={onClick}
        >
            {value}
        </button>
    );
}