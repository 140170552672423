export type PSCSummary = {
    vessel_imo: number;
    vessel_name: string;
    vessel_type: string;
    num_inspections_past_year: number;
    num_deficiencies_past_year: number;
    num_detentions_past_year: number;
    ratio_past_year: number;
    detention_ratio_past_year: number;
    num_inspections_past_3_years: number;
    num_deficiencies_past_3_years: number;
    num_detentions_past_3_years: number;
    ratio_past_3_years: number;
    detention_ratio_past_3_years: number;
    date: number | undefined;
    days_detained: number | undefined;
    detained: string | undefined;
    released: number | undefined;
}

export type PSCInspection = {
    vessel_imo: number;
    vessel_name: string;
    vessel_type: string; 
    date: number;
    days_detained: number;
    detained: string;
    expanded: string;
    follow_up: string;
    inspection_id: number;
    inspection_type: string;
    location: string;
    location_code: string;
    country: string;
    mou: string;
    number_of_deficiencies: number;
    released: number;
}

export type PSCDeficiency = {
    vessel_imo: number;
    vessel_name: string;
    vessel_type: string;
    deficiency_id: string;
    defect_code: string;
    defective_item_code: string;
    detainable: boolean;
    is_accidental_damage: boolean;
    main_defect_text: string;
    nature_of_defect_code: string;
    nature_of_defect_decode: string;
    inspection_id: number | string;
    inspection_type: string | undefined;
    location: string | undefined;
    country: string | undefined;
    mou: string | undefined;
    date: number | undefined;
    
}

export enum PSCTabs {
    performance,
    inspections,
    deficiencies,   
}

export const PSCInspectionTypes = [
    "initial",
    "more",
    "expanded",
    "follow",
    "unknown",
]

export const PSCTimePeriod = [
    "last36Months",
    "last12Months",
]