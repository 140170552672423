export const INSPECTION_STATUS_FULL_REPORT: number = 7;
export const INSPECTION_STATUS_COMPLETED: number = 5;

export const inspectionAndPredictedGradeRequestKey = "inspectionAndPredictedGradeData";
export const notificationRequestKey = "notifications";
export const fleetRequestKey = "fleetData";

export const TEN_MINUTES_IN_MS = (10*60*1000);
export const GRADING_AREA_ORDER = [
    "Design and Construction",
    "Hull",
    "Mooring Decks",
    "Weather Decks and Fittings",
    "Ballast Tanks and Systems",
    "Accommodation",
    "Bridge and Navigation Equipment",
    "Engine Room and Machinery",
    "Fire Fighting Equipment and Systems",
    "Lifesaving Appliances",
    "Safe Working Environment",
    "Pollution Control",
    "Onboard Management",
    "Vessel Capabilities and Cargo Systems",
    "Forthcoming Regulatory Compliance",
    "Crew Welfare",
    "Crew Performance",
    "Safety Management",
    "Planned Maintenance System (PMS)",
    "Classification and Certification",
    "PSC Performance"
];

export const DEFAULT_STAT_TILES_ORDER = {
    "vesselsOverdueInspection": 1,
    "vesselsDueInspection": 2,
    "vesselWithMostHighPriorityOpenDefects": 3,
    "subgradeWithWorstDeviationAcrossFleet": 4,
    "worstPerformingTechnicalManager": 5,
    "vesselWithLowestCrewWelfare": 6,
    "worstPerformingClassSociety": 7,
    "vesselWithLowestPscPerformance": 8,
    "averageDefectsPerVesselAcrossFleet": 9,
    "lowestIdwalGradeVessels": 10,
    "averageIdwalGrade": 11
}

export const GROUP_DROP_DOWN_KEY = "idwal-group_drop-down";

export const GROUP_DROP_DOWN_ALL_CODE = "All";

export const GROUP_DROP_DOWN_ONE_GROUP_COUNT = 2;

export const STAT_TILE_OVERDUE =  "vesselsOverdueInspection"
export const STAT_TILE_DUE_INSPECTION =  "vesselsDueInspection"
export const STAT_TILE_ID_INSPECTIONS_COMPLETED =  "IDInspectionsOrdered"
export const MAX_VISIBLE_TILES = 4;

export const FILTER_MAX_MULTISELECT_LABELS_TO_SHOW = 0;

export const CUSTOM_COLUMN_PREFIX = "custom_";

export const USER_APP_STATE_HOME_TABLE_KEY = "homeTable";
export const USER_APP_STATE_DEFECTS_TABLE_KEY = "defectsTable";
export const USER_APP_STATE_SUBGRADE_TABLE_KEY = "subgradeTable";

export const DEFAULT_TABLE_CONFIG = {
    filters: {},
    multiSortMeta: [],
    first: 0,
    rows: 10
};