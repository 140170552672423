import { Route, Routes } from 'react-router-dom';
import { useCapabilities } from '../../hooks/useCapabilities';
import { Capability } from '../../types/Capability';
import { SecuredRoute } from '../Authentication/SecuredRoute';
import Fleets from '../../pages/Fleets';
import Defects from '../../pages/Defects';
import DefectRectification from '../../pages/DefectRectification';
import Grade from '../../pages/Grade';
import SubGradesPage from '../../pages/SubGrades';
import SharedDefects from '../../pages/SharedDefects';
import PortStateControl from '../../pages/PortStateControl';
import {DEFECTS, GRADE, PSC, RECTIFICATIONS, SUBGRADES} from "../../types/RouteNames";

export const MainRoutes = () => {
    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_VIEW_DEFECTS,
        Capability.CAP_FLEETAPP_VIEW_FLEETS,
        Capability.CAP_FLEETAPP_VIEW_GRADES,
        Capability.CAP_FLEETAPP_VIEW_SUBGRADES,
        Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS,
        Capability.CAP_FLEETAPP_VIEW_PSC,
    ]);

    const routes = [];

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_FLEETS]) {
        routes.push(
            <Route key="fleet" path="/" element={<SecuredRoute />}>
                <Route path="/" element={<Fleets />} />
            </Route>
        );
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_GRADES]) {
        routes.push(
            <Route key={GRADE} path={`/${GRADE}`} element={<SecuredRoute />}>
                <Route path={`/${GRADE}`} element={<Grade />} />
            </Route>
        );
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_SUBGRADES]) {
        routes.push(
            <Route key={SUBGRADES} path={`/${SUBGRADES}`} element={<SecuredRoute />}>
                <Route path={`/${SUBGRADES}`} element={<SubGradesPage />} />
            </Route>
        );
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_DEFECTS]) {
        routes.push(
            <Route key="defects" path={`/${DEFECTS}`} element={<SecuredRoute />}>
                <Route path={`/${DEFECTS}`} element={<Defects />} />
                <Route path=":defectId/rectification" element={<DefectRectification />} />
            </Route>
        );
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS]) {
        routes.push(
            <Route key="rectifications" path="/" element={<SecuredRoute />}>
                <Route path="/" element={<SharedDefects />} />
                <Route path={`/${RECTIFICATIONS}`} element={<SharedDefects />} />
                <Route path=":defectId/rectification" element={<DefectRectification sharedDefect />} />
                <Route path={`/${RECTIFICATIONS}/:defectId/rectification`} element={<DefectRectification sharedDefect />} />
            </Route>
        );
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_PSC]) {
        routes.push(
            <Route key="psc" path="/" element={<SecuredRoute />}>
                <Route path={`/${PSC}`} element={<PortStateControl />} />
            </Route>
        );
    }
    
    return <Routes>{routes}</Routes>;
}