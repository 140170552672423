import { applyGlobalFilter } from "../../components/Shared/GlobalFilter";
import { getPSCDeficienciesData, getPSCInspectionsData, getPSCSummaryData } from "../../services/PSCService";
import { selectGlobalFilters } from "../selectors/filtersSelectors";
import { selectFleetDataItems } from "../selectors/fleetSelectors";
import { selectPSC } from "../selectors/pscSelector";
import { selectUserToken } from "../selectors/userSelectors";
import { setSummaryData, setInspectionsData, setDeficienciesData, setError, setLoading, setGlobalFilteredIMOs, setTimePeriod } from "../slices/pscSlice";
import { AppThunk } from "../store";
import { PSCTimePeriod } from "../../types/psc";
import { mapPSCDeficienciesData, mapPSCSummaryData, mapVesselData } from "../../utils/PSCDataHelper";

export const fetchPSCSummary = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const userToken = selectUserToken(state);

    if (userToken) {
        try {
            const response = await getPSCSummaryData(userToken);

            if (response?.length) {
                dispatch(setSummaryData(response));
            } else {
                throw Error('no data');
            }
        } catch (e) {
            console.error(e);
            dispatch(setError(true));
        }
    }
};

export const fetchPSCInspections = (): AppThunk => async (dispatch, getState) => {
    const userToken = selectUserToken(getState());

    if (userToken) {
        try {
            const response = await getPSCInspectionsData(userToken);

            if (response?.length) {
                dispatch(setInspectionsData(response));
            } else {
                throw Error('no data');
            }
        } catch (e) {
            console.error(e);
            dispatch(setError(true));
        }
    }
};

export const fetchPSCDeficiencies = (): AppThunk => async (dispatch, getState) => {
    const userToken = selectUserToken(getState());

    if (userToken) {
        try {
            const response = await getPSCDeficienciesData(userToken);

            if (response?.length) {
                dispatch(setDeficienciesData(response));
            } else {
                throw Error('no data');
            }
        } catch (e) {
            console.error(e);
            dispatch(setError(true));
        }
    }
};

export const setupPSCVesselData = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const { summaryData, inspectionsData, deficienciesData } = selectPSC(state);
    const fleetDataItems = selectFleetDataItems(state);

    const imos = summaryData.map((summary) => summary.vessel_imo); 

    const vesselNames: Record<number, string> = {};
    const vesselTypes: Record<number, string> = {};

    imos.forEach((imo) => {
        const match = fleetDataItems.find((fleetDataItem) => Number(fleetDataItem.vessel.imo) === imo);

        if (match) {
            vesselNames[imo] = match.vessel.vesselName;
            vesselTypes[imo] = match.vessel.type;
        }
    })

    const mappedInspectionsData = mapVesselData(inspectionsData, vesselNames, vesselTypes).sort((a, b) => b.date - a.date);
    dispatch(setInspectionsData(mappedInspectionsData));

    const mappedSummaryData = mapVesselData(summaryData, vesselNames, vesselTypes);
    const mappSummaryWithLatestData = mapPSCSummaryData(mappedSummaryData, mappedInspectionsData);
    dispatch(setSummaryData(mappSummaryWithLatestData));

    const mappedDeficienciesData = mapPSCDeficienciesData(deficienciesData, mappedInspectionsData);
    dispatch(setDeficienciesData(mappedDeficienciesData));

    dispatch(setLoading(false));
}

export const setupFilteredPSC = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const fleetDataItems = selectFleetDataItems(state);
    const globalFilters = selectGlobalFilters(state);

    if (fleetDataItems.length) {
        const filteredData = fleetDataItems.filter((item) => 
            applyGlobalFilter(globalFilters, item.vessel.vesselName, item.vessel.type, item.vessel.technicalManager, item.vessel.shipAge));

        const imos = filteredData.map((item) => Number(item.vessel.imo));

        dispatch(setGlobalFilteredIMOs(imos));
    }
}

export const setupPSCSummaryDataByTimePeriod = (timePeriod: string): AppThunk => (dispatch, getState) => {
    const { summaryData, inspectionsData } = selectPSC(getState());

    dispatch(setTimePeriod(timePeriod));

    const oneYear = timePeriod === PSCTimePeriod[1];
    const mappSummaryWithLatestData = mapPSCSummaryData(summaryData, inspectionsData, oneYear);
    dispatch(setSummaryData(mappSummaryWithLatestData));
}