import { DataTable, DataTableStateEvent} from "primereact/datatable";
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./FleetTable.css";
import { Tooltip} from 'primereact/tooltip';
import { useTranslation} from "react-i18next";
import { addCustomFilters, registerCustomFilters} from "./FilterTemplates";
import {actionBodyTemplate} from "../Shared/BodyTemplates";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import {
    addNewColumn,
    buildColumns,
    loadSavedColumnConfigurationsV2,
    onColumnReorder,
    onColumnToggle,
    removeCustomColumn,
    updateStoredTableConfigState
} from "../Shared/TableFunctions";
import { DefaultHeader } from "../Shared/DefaultHeader";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { SharedTableColumnConfig } from "../../types/SharedTableColumnConfig";
import { columnTemplates, filterTemplates, keywordSearchFilterFields } from "./Constants";
import { getDefaultFleetColumnsConfig } from "./Columns/ColumnConfigs";
import { addEnabledColumns, createCustomColumns } from "./Columns/ColumnHelper";
import { HeaderType } from "../../types/HeaderType";
import { SelectItemOptionsType } from "primereact/selectitem";
import { RemoveCustomColumnParams } from "../../types/RemoveColumnProps";
import { CUSTOM_COLUMN_PREFIX, USER_APP_STATE_HOME_TABLE_KEY } from "../../utils/Constants";
import {actionColumn, vesselColumn} from "./Columns/StaticColumns";
import { onCustomRestoreState } from "../../utils/TableUtils";
import {vesselBodyTemplate} from "@idwal/idwal-react-components";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectAiGradingActive, selectUserDetails } from "../../store/selectors/userSelectors";
import { selectDataTableFilterData, selectKeywordSearch } from "../../store/selectors/filtersSelectors";
import { setFleetTableFilterData } from "../../store/slices/filtersSlice";
import { selectVesselByImo } from "../../store/thunks/vesselViewThunks";
import { selectFleet } from "../../store/selectors/fleetSelectors";
import { setRequestInspectionDialog, updateFleetDataItem } from "../../store/slices/fleetSlice";
import { UserDetails } from "../../types/UserDetails";
import { setUserDetails } from "../../store/slices/userSlice";
import { cloneDeep } from "lodash";
import { FleetDataItem } from "../../types/FleetDataItem";

export const FleetTable = (): ReactElement => {
    const {t} = useTranslation("locale");
    const dispatch = useAppDispatch();

    const fleetTableRef = useRef<any>(null);

    const { 
        loading,
        fleetDataItems,
        inspectionAndGradeDataLoading,
        distinctVesselTypes,
        distinctTechnicalManagers,
        distinctCountriesOfBuild,
        distinctFlags,
    } = useAppSelector(selectFleet)
    const userDetails = useAppSelector(selectUserDetails);
    const keywordSearch = useAppSelector(selectKeywordSearch);
    const dataTableFilterData = useAppSelector(selectDataTableFilterData);
    const aiGradingActive = useAppSelector(selectAiGradingActive);

    registerCustomFilters();

    const isLoading = loading || inspectionAndGradeDataLoading;

    const updateRowItem = (item: FleetDataItem) => {
        dispatch(updateFleetDataItem(item));
    }

    const [columns, setColumns] = useState<SharedTableColumnConfig[]>(getDefaultFleetColumnsConfig(updateRowItem));

    const [selectedColumns, setSelectedColumns] = useState(columns);

    const tableDataItems = useMemo(() => 
        cloneDeep(fleetDataItems)
    , [fleetDataItems]);

    useEffect(() => {
        if (loading) {
            return;
        }

        if (!userDetails?.groupDetails) {
            return;
        }

        let enabledColumns : any[] = [];

        for (const group of Object.keys(userDetails.groupDetails))
        {
            const specialColumnsProp = userDetails.groupDetails[group].specialColumnsEnabled;
            if (specialColumnsProp){
                const specialColumns = JSON.parse(specialColumnsProp);

                for (const specialColumn of specialColumns) {
                    if (!enabledColumns.includes(specialColumn))
                    {
                        enabledColumns.push(specialColumn);
                    }
                }
            }
        }

        let cols: SharedTableColumnConfig[] = [];
        if (enabledColumns)
        {
            enabledColumns = addEnabledColumns(enabledColumns, columns);
            cols = enabledColumns;
        }

        const customCols = createCustomColumns(tableDataItems);

        customCols.forEach((customColumn) => {
            const locallySavedCustomColumn = cols.find(element => customColumn.field === element.field && element.field.startsWith(CUSTOM_COLUMN_PREFIX));
            if (locallySavedCustomColumn) {
                const indexToUpdate = cols.indexOf(locallySavedCustomColumn)
                cols[indexToUpdate] = customColumn;
            }
            if (!locallySavedCustomColumn && !columns.find(element => customColumn.field === element.field)) {

                columns.push(customColumn);
            }
        });

        loadSavedColumnConfigurationsV2(columns, setColumns, setSelectedColumns, userDetails, userDetails.appState?.homeTable, USER_APP_STATE_HOME_TABLE_KEY);
    }, [loading, tableDataItems]);

    useEffect(() => {
        const customColumnFields = columns.filter((col) => col.field.startsWith(CUSTOM_COLUMN_PREFIX)).map((col) => col.field);
        if (!customColumnFields.length) {
            return;
        }

        const customFilters = addCustomFilters(customColumnFields, dataTableFilterData.table);
        dispatch(setFleetTableFilterData(customFilters));
    }, [columns]);

    const removeColumnParams = (columnName: string) => {
        return {
            columnName, 
            columns, 
            selectedColumns, 
            setColumns, 
            setSelectedColumns, 
            userDetails, 
            localStorageSelectedColumnsKey: LOCAL_TABLE_CONFIG_KEY.FLEET_COLUMNS, 
            tableKey: USER_APP_STATE_HOME_TABLE_KEY
        } as RemoveCustomColumnParams;
    }

    const onFilter = (event: DataTableStateEvent) => {
        dispatch(setFleetTableFilterData(event.filters));
    }

    const selectVessel = (rowData: any) => {
        dispatch(selectVesselByImo(rowData.vessel.imo));
    }

    const onAddNewColumn = (newColumns: SharedTableColumnConfig[], selected: SharedTableColumnConfig[]) => {
        setColumns(newColumns);
        setSelectedColumns(selected);
    }

    const header = () => <DefaultHeader 
        onColumnToggle={
            (e: MultiSelectChangeEvent) => onColumnToggle(
                e,
                LOCAL_TABLE_CONFIG_KEY.FLEET_COLUMNS,
                columns,
                setSelectedColumns,
                userDetails,
                USER_APP_STATE_HOME_TABLE_KEY)}
        columns={columns}
        disabled={loading && inspectionAndGradeDataLoading}
        headerType={HeaderType.TABLE}
        selectedColumns={selectedColumns}
        filters={dataTableFilterData.table}
        onColumnReorder={
            (orderedColumns: any) => onColumnReorder(
                orderedColumns,
                LOCAL_TABLE_CONFIG_KEY.FLEET_COLUMNS,
                LOCAL_TABLE_CONFIG_KEY.FLEET_ORDERED_COLUMNS,
                columns,
                selectedColumns,
                setColumns,
                setSelectedColumns,
                userDetails,
                USER_APP_STATE_HOME_TABLE_KEY)}
        onAddColumn={(columnName: string, dataType: string) => 
            addNewColumn(columnName, dataType, selectedColumns, userDetails, onAddNewColumn, USER_APP_STATE_HOME_TABLE_KEY, columns)}
        onRemoveColumn={(columnName: string) => removeCustomColumn(removeColumnParams(columnName))}
    />

    const inspectionStatusFilterOptions: SelectItemOptionsType[] = [
        t("inspectionStatuses.ordered"),
        t("inspectionStatuses.inProgress"),
        t("inspectionStatuses.inspectionComplete"),
        t("inspectionStatuses.fullReport"),
        t("inspectionStatuses.neverInspected")
    ];

    const requestInspectionShow = (rowData: any) => {
        dispatch(setRequestInspectionDialog({
            show: true,
            vessels: [rowData]
        }));
    }

    const columnComponents = buildColumns(selectedColumns,
        columnTemplates(aiGradingActive, requestInspectionShow),
        filterTemplates(distinctVesselTypes, distinctTechnicalManagers, distinctFlags, distinctCountriesOfBuild, inspectionStatusFilterOptions), () => "12rem");

    const updateUserDetails = (userDetails: UserDetails) => {
        dispatch(setUserDetails(userDetails));
    }

    const customSaveState = (state: any) => {
        updateStoredTableConfigState(
            USER_APP_STATE_HOME_TABLE_KEY,
            columns,
            selectedColumns,
            userDetails,
            updateUserDetails,
            userDetails?.appState?.homeTable,
            state,
            LOCAL_TABLE_CONFIG_KEY.FLEET
        );
    }
    
    const customRestoreState = () => {
        return onCustomRestoreState(LOCAL_TABLE_CONFIG_KEY.FLEET, keywordSearch.table, userDetails, USER_APP_STATE_HOME_TABLE_KEY);
    };

    if (isLoading) {
        return (<div className={"table-container home-table"}  data-cy="fleet-table-container-loading">
            <DataTable
                loading 
                header={header()} 
                filters={dataTableFilterData?.table}
                filterDisplay="row"
                data-cy="fleet-table-loading"
            >
                {vesselColumn((e: any) => vesselBodyTemplate(e, selectVessel))}
                {actionColumn( (e: any) => actionBodyTemplate(e, true, requestInspectionShow))}
                {columnComponents}
            </DataTable>
        </div>)
    }

    return (
            <div className={"table-container home-table"}  data-cy="fleet-table-container">
                <Tooltip target=".request-inspection" content={t("fleetTable.requestInspection")} position="left" />
                <DataTable
                    ref={fleetTableRef}
                    globalFilterFields={keywordSearchFilterFields(aiGradingActive)}
	                scrollable
                    data-cy="fleet-table"
                    filters={dataTableFilterData?.table}
	                filterDisplay="row"
	                header={header}
	                value={tableDataItems}
                    onFilter={onFilter}
                    stateStorage="custom"
                    customSaveState={(state) => customSaveState(state)} 
                    customRestoreState={customRestoreState}
                    loading={isLoading}
                    emptyMessage={t("fleetTable.noDataMessage")}
                    removableSort
                    editMode="cell"
                    stripedRows
                    resizableColumns
                    columnResizeMode="expand">
                    {vesselColumn((e: any) => vesselBodyTemplate(e, selectVessel))}
                    {actionColumn( (e: any) => actionBodyTemplate(e, true, requestInspectionShow))}
                    {columnComponents}
                </DataTable>
            </div>
    )
}
