import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {GROUP_DROP_DOWN_KEY, GROUP_DROP_DOWN_ALL_CODE, GROUP_DROP_DOWN_ONE_GROUP_COUNT} from "../../utils/Constants";
import {getGroupNameFromLocalStore} from "../../utils/GroupDropDownHelper";
import "./GroupDropDown.css";
import { GroupSelectorDropdown } from '@idwal/idwal-react-components';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { selectFleetData } from '../../store/selectors/fleetSelectors';
import { setupFleetDataWithInspectionsBySelectedGroup } from '../../store/thunks/fleetThunks';
import { setupFilteredDefects } from '../../store/thunks/defectThunks';
import { setupFilteredPSC } from "../../store/thunks/pscThunks";

export function GroupDropDown() {
    const {t} = useTranslation("locale");
    const dispatch = useAppDispatch();

    const fleetData = useAppSelector(selectFleetData);

    const [loaded, setLoaded] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<any>("null");
    const allGroupsName = t("groupDropDown.all")

    const allGroupsDropDownOption = {
        name: allGroupsName,
        code: allGroupsName
    };

    const [groups, setGroups] = useState([allGroupsDropDownOption]);

    const updateGroupDropDown = (groupNames: string[]) => {
        let newGroups: any[] = [];
        newGroups.push(allGroupsDropDownOption);
        groupNames.forEach((groupName: string) => {
            newGroups.push({
                name: groupName,
                code: groupName
            })
        });
        setGroups(newGroups);
    }

    const setGroupDropDownFromLocalStorage = () => {
        let localStorageGroup = getGroupNameFromLocalStore();

        setSelectedGroup({
            name: localStorageGroup,
            code: localStorageGroup
        });
    }

    const validateLocalStorageGroup = (groupNames : string[]) => {
        let localStorageGroup = getGroupNameFromLocalStore();

        if(localStorageGroup != GROUP_DROP_DOWN_ALL_CODE && !groupNames.includes(localStorageGroup)){
            localStorage.setItem(GROUP_DROP_DOWN_KEY, GROUP_DROP_DOWN_ALL_CODE);
        }
    }

    useEffect(() => {
        if (fleetData.userDetails?.groupDetails) {
            const groupNames = Object.getOwnPropertyNames(fleetData.userDetails.groupDetails);

            if (groupNames.length) {
                updateGroupDropDown(groupNames);
    
                if(groupNames.length == 1){
                    localStorage.setItem(GROUP_DROP_DOWN_KEY, GROUP_DROP_DOWN_ALL_CODE);
                } else {
                    validateLocalStorageGroup(groupNames);
                    setGroupDropDownFromLocalStorage();
                }

                setLoaded(true);
            }
        }
    }, [fleetData]);

    const onGroupSelected = (event: any) => {
        setSelectedGroup(event.value);
        const groupName = event.value['code'];
        localStorage.setItem(GROUP_DROP_DOWN_KEY, groupName);
        dispatch(setupFleetDataWithInspectionsBySelectedGroup(groupName));
        dispatch(setupFilteredDefects());
        dispatch(setupFilteredPSC());
    }

    if (!loaded) {
        return (
            <div className="p-3"></div>
        )
    }

    if (groups.length == GROUP_DROP_DOWN_ONE_GROUP_COUNT) {
        return (
            <div className="p-3">{groups[1].name}</div>
        )
    }

    return (
        <GroupSelectorDropdown selectedGroup={selectedGroup.code} groups={groups} onGroupChange={onGroupSelected} />
    )
}