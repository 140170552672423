import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectPSC } from "../../store/selectors/pscSelector";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { setDeficienciesFilters, setDeficienciesPage } from "../../store/slices/pscSlice";
import { PSCTableHeader } from "./PSCTableHeader";
import { pscDeficienciesColumns, pscGlobalFilterFields } from "../../utils/PSCTableHelper";
import { FilterMatchMode } from "primereact/api";
import { filterByIMOsAndTimePeriod } from "../../utils/PSCDataHelper";

export const PSCDeficienciesTable = () => {
    const dispatch = useAppDispatch();

    const {
        deficienciesData,
        loading,
        globalFilterValue,
        globalFilteredIMOs,
        deficienciesFilters,
        deficienciesPage,
        timePeriod,
    } = useAppSelector(selectPSC);

    const onPage = useCallback((event: DataTableStateEvent) => {
        dispatch(setDeficienciesPage({ row: event.rows, first: event.first}))
    }, []);

    const onFilter = useCallback((event: DataTableStateEvent) => {
        dispatch(setDeficienciesFilters(event.filters));
    }, []);

    const renderHeader = useMemo(() => (
        <PSCTableHeader />
    ), [globalFilterValue]);

    const value = useMemo(() => (
        filterByIMOsAndTimePeriod(deficienciesData, globalFilteredIMOs, timePeriod)
    ), [deficienciesData, globalFilteredIMOs, timePeriod]);

    const columns = useMemo(() => (
        pscDeficienciesColumns()
    ), [deficienciesData]);

    const rowOptions = useMemo(() => 
        [5, 10, 25, 50]
    , []);

    const globalFilterFields = useMemo(() => 
        pscGlobalFilterFields
    , []);
    
    return (
        <DataTable 
            value={value}
            loading={loading}
            filters={deficienciesFilters}
            onFilter={onFilter}
            globalFilter={globalFilterValue}
            globalFilterMatchMode={FilterMatchMode.CONTAINS}
            globalFilterFields={globalFilterFields}
            data-cy="psc-deficiencies-table"
            className="psc-deficiencies-table"
            filterDisplay="row"
            sortField="date"
            sortOrder={-1}
            paginator
            scrollable
            removableSort
            stripedRows
            first={deficienciesPage.first}
            rows={deficienciesPage.row}
            rowsPerPageOptions={rowOptions}
            onPage={onPage}
            header={renderHeader}
        >
            {columns}
        </DataTable>
    )
};