import { PSCSummary } from "../../types/psc";
import { classNames } from "primereact/utils";

export const PSCDetentionsBody = (oneYear: boolean) => (item: PSCSummary) => {
    const value = oneYear ? item.num_detentions_past_year : item.num_detentions_past_3_years;

    const onClick = () => console.log(item.num_detentions_past_3_years);

    return (
        <button 
            className={classNames("font-semibold psc-table-link-btn", value ? "cursor-pointer read-more-btn underline" : null)}
            onClick={onClick}
        >
            {value}
        </button>
    );
}