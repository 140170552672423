import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectPSC } from "../../store/selectors/pscSelector";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { setPerformancePage } from "../../store/slices/pscSlice";
import { PSCTableHeader } from "./PSCTableHeader";
import { pscPerformanceColumns, pscGlobalFilterFields, pscPerformanceHeaders } from "../../utils/PSCTableHelper";
import { FilterMatchMode } from "primereact/api";
import { PSCTimePeriod } from "../../types/psc";
import "./PSCTable.css";
import { filterByIMOsAndTimePeriod } from "../../utils/PSCDataHelper";

export const PSCPerformanceTable = () => {
    const dispatch = useAppDispatch();

    const {
        summaryData,
        loading,
        globalFilterValue,
        globalFilteredIMOs,
        performancePage,
        timePeriod,
    } = useAppSelector(selectPSC);

    const onPage = useCallback((event: DataTableStateEvent) => {
        dispatch(setPerformancePage({ row: event.rows, first: event.first}))
    }, []);

    const renderHeader = useMemo(() => (
        <PSCTableHeader />
    ), [globalFilterValue]);

    const value = useMemo(() => (
        filterByIMOsAndTimePeriod(summaryData, globalFilteredIMOs)
    ), [summaryData, globalFilteredIMOs]);

    const oneYear = timePeriod === PSCTimePeriod[1];

    const headers = useMemo(() => (
        pscPerformanceHeaders(oneYear)
    ), [summaryData, oneYear]);

    const columns = useMemo(() => (
        pscPerformanceColumns(oneYear)
    ), [summaryData, oneYear]);

    const rowOptions = useMemo(() => 
        [5, 10, 25, 50]
    , []);

    const globalFilterFields = useMemo(() => 
        pscGlobalFilterFields
    , []);
    
    return (
        <DataTable 
            value={value}
            loading={loading}
            globalFilter={globalFilterValue}
            globalFilterFields={globalFilterFields}
            globalFilterMatchMode={FilterMatchMode.CONTAINS}
            data-cy="psc-performance-table"
            className="psc-performance-table"
            paginator
            scrollable
            removableSort
            stripedRows
            first={performancePage.first}
            rows={performancePage.row}
            rowsPerPageOptions={rowOptions}
            onPage={onPage}
            header={renderHeader}
            headerColumnGroup={headers}
        >
            {columns}
        </DataTable>
    )
};