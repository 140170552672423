import { getFleetToken } from "../../services/FleetDataService";
import { getUserCapabilities, getUserHasAccess } from "../../services/UserService";
import { Capability } from "../../types/Capability";
import { selectUser } from "../selectors/userSelectors";
import { setHasFleetAccess, setUserCapabilities, setUserId, setUserLoaded, setUserToken } from "../slices/userSlice";
import { AppThunk } from "../store";
import { fetchAgeOfOpenDefects, fetchClosedRatios, fetchCumulativeDefects } from "./defectInsightsThunks";
import { fetchDefects } from "./defectThunks";
import { fetchFleetData, fetchInspectionAndGradeData, setupFleetData } from "./fleetThunks";
import { fetchFleetGraph, fetchSubgradeBackingData, fetchSubgradeData } from "./fleetGraphThunks";
import { fetchPreviousRecipients, fetchSharedDefects } from "./sharedDefectsThunks";
import { AmplifyUser } from "@aws-amplify/ui";
import { fetchNotifications } from "./notificationsThunks";
import { fetchPSCDeficiencies, fetchPSCInspections, fetchPSCSummary, setupPSCVesselData } from "./pscThunks";
import { LDFlagSet } from "launchdarkly-react-client-sdk";

export const initFetchData = (user: AmplifyUser, flags: LDFlagSet): AppThunk => async (dispatch, getState) => {
    const { userLoaded } = selectUser(getState());

    if (user && !userLoaded) {
        dispatch(setUserId(user.username));
 
        dispatch(setHasFleetAccess(getUserHasAccess(user)));

        const userCapabilities = await getUserCapabilities(user.username);

        dispatch(setUserCapabilities(userCapabilities));

        await dispatch(fetchDataByCapabilities(userCapabilities));

        dispatch(setupFleetData(flags));

        dispatch(setUserLoaded(true));
    }
};

const fetchDataByCapabilities = (userCapabilities: string[]): AppThunk<Promise<boolean>> => async (dispatch) => {
    const capabilities = Object.fromEntries(
        userCapabilities.map((capability) => [capability, userCapabilities.includes(capability)]),
    )

    const fetchActions = [];

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_FLEETS]) {
        fetchActions.push(dispatch(fetchFleetData()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_GRADES] || capabilities[Capability.CAP_FLEETAPP_VIEW_SUBGRADES]) {
        fetchActions.push(dispatch(fetchInspectionAndGradeData()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_DEFECTS]) {
        fetchActions.push(dispatch(fetchDefects()));
        fetchActions.push(dispatch(fetchPreviousRecipients()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS]) {
        fetchActions.push(dispatch(fetchSharedDefects()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_GRADES]) {
        fetchActions.push(dispatch(fetchFleetGraph()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_VIEW_SUBGRADES]) {
        fetchActions.push(dispatch(fetchSubgradeData()));
        fetchActions.push(dispatch(fetchSubgradeBackingData()));
    }

    if (capabilities[Capability.CAP_FLEETAPP_NOTIFICATIONS]) {
        fetchActions.push(dispatch(fetchNotifications()));
    }

    await Promise.all(fetchActions);

    return true;
}

export const initFetchTokenData = (): AppThunk => async (dispatch, getState) => {
    const { userId, userLoaded } = selectUser(getState());

    if (userId && !userLoaded) {

        try {
            const userToken = await getFleetToken();

            if (userToken) {
                dispatch(setUserToken(userToken));
         
                dispatch(fetchClosedRatios());
                dispatch(fetchCumulativeDefects());
                dispatch(fetchAgeOfOpenDefects());

                const pscPromoises = [
                    dispatch(fetchPSCSummary()),
                    dispatch(fetchPSCInspections()),
                    dispatch(fetchPSCDeficiencies()),
                ];

                await Promise.all(pscPromoises);

                dispatch(setupPSCVesselData());
            } 
        } catch (e) {
            console.error(e);
        }
    }
}