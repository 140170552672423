import dayjs from "dayjs";
import { groupBy } from "lodash";
import { PSCDeficiency, PSCInspection, PSCSummary, PSCTimePeriod } from "../types/psc";

export const filterByIMOsAndTimePeriod = <T extends { vessel_imo: number, date?: number }>
    (data: T[], imos: number[], timePeriod?: string) => {
        // API data already filter by 36 months so only have to filter when 12 months selected     
        if (timePeriod === PSCTimePeriod[1]) {
            const timePeriodTimestamp = dayjs().subtract(12, "month").unix();

            return data.filter((item) => imos.includes(item.vessel_imo) && item.date && item.date >= timePeriodTimestamp);
        }
        
        return data.filter((item) => imos.includes(item.vessel_imo));
    }

export const mapVesselData = <T extends { vessel_imo: number }>
    (data: T[], vesselNames: Record<number, string>, vesselTypes: Record<number, string>) => 
        data.map((item) => ({
            ...item,
            vessel_name: vesselNames[item.vessel_imo],
            vessel_type: vesselTypes[item.vessel_imo],
        })
    );

export const mapPSCSummaryData = (
    data: PSCSummary[],
    inspections: PSCInspection[],
    oneYear?: boolean,
) => data.map((item) => {
    const latest = inspections.find((inspection) => inspection.vessel_imo === item.vessel_imo)

    if (latest) {
        if (oneYear) {
            const timePeriodTimestamp = dayjs().subtract(12, "month").unix();
            
            if (latest.date >= timePeriodTimestamp) {
                return {
                    ...item,
                    date: latest.date,
                    days_detained: latest.days_detained,
                    detained: latest.detained,
                    released: latest.released,
                }
            } else {
                return {
                    ...item,
                    date: undefined,
                    days_detained: undefined,
                    detained: undefined,
                    released: undefined,
                }
            }
        } else {
            return {
                ...item,
                date: latest.date,
                days_detained: latest.days_detained,
                detained: latest.detained,
                released: latest.released,
            }
        }
    }

    return item;
});

export const mapPSCDeficienciesData = (
    data: PSCDeficiency[],
    inspections: PSCInspection[],
) => {
    const groupedDeficencies = groupBy(data, "inspection_id");
    const mappedDeficienciesData: PSCDeficiency[] = [];

    Object.entries(groupedDeficencies).forEach(([inspectionId, deficiencies]) => {
        const match = inspections.find((inspection) => inspection.inspection_id === Number(inspectionId))

        deficiencies.forEach((deficiency) => {
            if (match) {
                mappedDeficienciesData.push({
                    ...deficiency,
                    vessel_imo: match.vessel_imo,
                    vessel_name: match.vessel_name,
                    vessel_type: match.vessel_type,
                    inspection_type: match.inspection_type,
                    location: match.location,
                    country: match.country,
                    mou: match.mou,
                    date: match.date,
                })
            } else {
                mappedDeficienciesData.push(deficiency);
            }
        })
    })

    return mappedDeficienciesData;
}